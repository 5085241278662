import { reactive, readonly, computed } from "vue";
import axios from 'axios'
import 'js-loading-overlay'

const state = reactive({
    pageLoading: false,
    auth: {
        logged: false,
        token: '',
        refreshToken: '',
        tokenExpires: 0,
        user: {}
    },
    notification: {
        open: false,
        icon: '',
        title: '',
        message: ''
    },
    screenType: 'desktop',
    page: {
        menu: []
    },

    // modules: {
    // hotels:"hotels",
    // flights:"flights",
    // payments:"payments",
    // content: "content",
    // users:"users",
    // admins:"admins",
    // settings: "settings"
    // },
    isLoading: false,
    permissions: []
})


const setAuthToken = (token) => {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

export default {
    isMobile: computed(() => state.screenType == 'mobile'),
    isTablet: computed(() => state.screenType == 'tablet'),
    isDesktop: computed(() => state.screenType == 'desktop'),
    isSuperAdmin: () => state.auth.user.type == 'super_admin',
    hasPermission: (permissions) => state.permissions.includes('*') || state.permissions.includes(permissions),
    // state,
    state: readonly(state),
    locale: reactive({}),
    setPageLoad(value) {
        state.pageLoading = value
    },
    setUser(logged, info) {
        state.auth = {...info, logged: logged}
        state.permissions = info.user && info.user.role && info.user.role.permissions || []
        window.localStorage.setItem('auth', JSON.stringify(state.auth))
        setAuthToken(info.token)
    },
    setAuthToken(token) {
        setAuthToken(token)
    },
    user_type() {
        let auth = window.localStorage.getItem('auth')
        if (auth) {
            auth = JSON.parse(auth)
            if (auth.logged) {
              return auth.user.type;
            }
        }
    },
    logout() {
        state.auth = {
            logged: false,
            token: '',
            user: {}
        }

        window.localStorage.removeItem('auth')
        delete axios.defaults.headers.common['Authorization']
        if (window.location.pathname != '/login') window.location.href = '/login'
    },
    setNotification(obj) {
        state.notification = { ...state.notification, ...obj }
    },
    setScreenType(type) {
        state.screenType = type
        // console.log(type)
    },
    setPage(page) {
        state.page = page
    },
    showLoading() {
        state.isLoading = true
        JsLoadingOverlay.show({
            'overlayBackgroundColor': '#666666',
            'overlayOpacity': 0.6,
            'spinnerIcon': 'ball-clip-rotate',
            'spinnerColor': 'orange',
            'spinnerSize': '2x',
            'overlayIDName': 'overlay',
            'spinnerIDName': 'spinner',
            'spinnerZIndex': 99999,
            'overlayZIndex': 99998
        })
    },
    hideLoading() {
        state.isLoading = false
        JsLoadingOverlay.hide()
    },
    isLoading() {
        return state.isLoading
    }
}