<template>
	<Layout />
</template>

<script setup>
import en from '@/lang/en.json'
import ar from '@/lang/ar.json'
import { provideI18n, defaultLocale, defaultCountryCode, defaultCurrency } from "@/plugins/i18n";
import Layout from '@/pages/__Layout.vue'
import { loadModulesMenu } from './utils/menu';

provideI18n({
  locale: defaultLocale(),
  currency: defaultCurrency(),
  countryCode: defaultCountryCode(),
  messages: {
    en,
    ar
  }
});

loadModulesMenu();
</script>