<template>
    <div class="relative w-auto">
        <div class="absolute p-1 cursor-pointer text-slate-500 bg-slate-200 dark:bg-slate-800" :class="{'end-0 rounded-bs-md': openMenu, 'rounded-be-md': !openMenu}" @click="toggle">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 " :class="{'transform flip-x rtl:flip-x': !openMenu}" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M15.707 15.707a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 010 1.414zm-6 0a1 1 0 01-1.414 0l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 1.414L5.414 10l4.293 4.293a1 1 0 010 1.414z" clip-rule="evenodd" />
            </svg>  
        </div>
        <div class="z-10 flex-shrink-0 hidden h-full px-3 overflow-y-auto bg-white shadow-sm text-slate-500 dark:text-slate-400 app-side-sub-menu dark:bg-slate-900 md:block border-e dark:border-slate-800" :class="{'w-52': openMenu, 'w-0': !openMenu}">
            <div v-if="openMenu">
                <template v-for="(item, key) in menu" :key="key">
                    <div v-if="item.type == 'title'" class="p-4 font-medium uppercase text-slate-800 dark:text-primary-200">{{ lang.$t(item.title) }}</div>
                    <router-link v-else :to="{name: item.link, params: {lang: lang.locale.value}}" 
                        class="flex items-center w-full px-4 py-2 mb-1 text-sm transition-colors duration-150 rounded-md text-slate-800 hover:bg-slate-50 dark:hover:bg-slate-800 hover:text-slate-800 dark:hover:text-slate-200 dark:text-slate-100"
                        :class="{'bg-slate-100 text-primary-600 dark:bg-slate-800 dark:text-primary-50': $route.name === item.link}"
                        >
                        <template v-if="item.icon_type == 'html'">
                            <span v-html="item.icon"></span>
                        </template>
                        <template v-else>
                            <i :class="item.icon"></i>
                        </template>
                        <span class="ms-2">{{ lang.$t(item.title) }}</span>
                    </router-link>
                </template>
            </div>
        </div>
    </div>
</template>
<script setup>
import { useI18n } from "@/plugins/i18n";
import { ref } from "@vue/reactivity";
const lang = useI18n();

defineProps({
    menu: Array
})
const openMenu = ref(true)

const toggle = () => {
    openMenu.value = !openMenu.value
}
</script>
