import { createRouter, createWebHistory } from "vue-router";
import { supportedLocales, defaultLocale, setLocale } from "@/plugins/i18n";
import { getCurrentPageMenu } from "@/utils/menu";
import store from '@/state'

const langParam = supportedLocales.join('|')
const routes = [
    { name: 'home', path: `/:lang(${langParam})?/`, component: () => import('@/pages/Home.vue') },

    //=======================================================================
    { name: 'settings', path: `/:lang(${langParam})?/settings`, component: () => import('@/pages/settings/Index.vue')},
    //=======================================================================
    { name: 'companies', path: `/:lang(${langParam})?/companies`, component: () => import('@/pages/companies/Companies.vue') },
    //=======================================================================
    { name: 'users', path: `/:lang(${langParam})?/users`, component: () => import('@/pages/users/Users.vue') },
    { name: 'users-roles', path: `/:lang(${langParam})?/users/roles`, component: () => import('@/pages/users/Roles.vue') },
    //========================================================================
    { name: 'leads', path: `/:lang(${langParam})?/leads/:category?`, component: () => import('@/pages/leads/Leads.vue') },
    { name: 'leads-categories', path: `/:lang(${langParam})?/leads/categories`, component: () => import('@/pages/leads/Categories.vue') },
    //========================================================================
    { name: 'campaigns', path: `/:lang(${langParam})?/campaigns`, component: () => import('@/pages/campaigns/Campaigns.vue') },
    //========================================================================
    { name: 'profile', path: `/profile`, component: () => import('@/pages/auth/Profile.vue') },
    { name: 'user-settings', path: `/:lang(${langParam})?/settings`, component: () => import('@/pages/settings/Index.vue') },
    { name: 'login', path: `/login`, component: () => import('@/pages/auth/Login.vue') },
    { name: 'forbidden', path: '/forbidden', component: () => import('@/pages/Forbidden.vue') },
    { path: '/:404(.*)*', component: () => import('@/pages/NotFound.vue') }
]

export const router = createRouter({
    // history: createWebHistory('/admin/dashboard_frontend/'),
    history: createWebHistory(),
    routes,
});
//============================================
//==== permission middleware
router.beforeEach(async (to, from) => {
    if(['login', 'forbidden'].includes(to.name)){
        return 
    }
    store.setPageLoad(true)
    // let value = await new Promise((resolve, reject) => {
    //     const waitUser = setInterval(() => {
    //         if(store.state.user && store.state.auth.info && store.state.auth.user.type){
    //             clearInterval(waitUser)
    //             resolve(true)
    //             // console.log(state.state.user.info)
    //         }
    //     }, 100)
    // }).then(() => {
    //     if (to.meta.userType && !store.isUserType(to.meta.userType)) {
    //         return {
    //             name: 'forbidden',
    //         }
    //     }else{
    //         return true
    //     }
    // })
    // console.log('value', value)
    store.setPageLoad(false)
    // return value
    return true
});
//==================================================

router.beforeEach((to, from, next) => {
    let { lang } = to.params
    if(lang && supportedLocales.includes(lang) && lang != defaultLocale()){
        setLocale(lang)
    }
    if(to.name){
        let module = to.name.split('-')[0]
        getCurrentPageMenu(module).then(menu => store.setPage({menu})) 
    }
    
    next()
});

router.afterEach(() => {
    document.body.scrollTop = 0; // For Safari
	document.documentElement.scrollTop = 0;
    store.setPageLoad(false)
})