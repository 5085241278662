import store from '@/state'
import {sortBy} from '@/utils/object'
import { ref } from 'vue';

const Menus = ref([])
const menusByModule = ref({})

export async function loadModulesMenu() {
    const moduleContext = import.meta.glob('../modules/*/menu.json');
    for (const path in moduleContext) {
        const mod = await moduleContext[path]();
        Menus.value.push(...mod.default)
        menusByModule.value[path.split('/')[2]] = mod.default
    }
    Menus.value = Menus.value.sort(sortBy('order'))
}

export function getSortedMenus() {
    return Menus.value;
}

export async function getCurrentPageMenu(page) {
    const menus = JSON.parse(JSON.stringify(menusByModule.value))
    let menu = menus[page] && menus[page].find(menu => menu.name == page ) || {}
    menu = menu.hasOwnProperty('submenu') ? menu.submenu : []
    return menu.filter(item => {
        if(!item.hasOwnProperty('permission')) return true;
        return store.hasPermission(item.permission)
    })
}