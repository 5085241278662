<template>
    <div class="fixed z-10 flex items-center w-auto px-5 py-4 rounded-md shadow-xl" style="max-width: 90%">
        <div class="pe-5">
            <i :class="(icon || 'flaticon-shield-1 ') + 'text-xl ' + (color || 'text-green-500')"></i>
        </div>
        <div class="flex-grow pe-6">
            <div :class="'font-medium '+ (color || 'text-green-500')">{{ title }}</div>
            <div>{{ message }}</div>
        </div>
        <div>
            <button type="button" @click="$emit('close')"><i class="text-sm text-gray-500 flaticon-cancel"></i></button>
        </div>
    </div>
</template>
<script setup>
import {  defineProps, onMounted } from "vue";

const props = defineProps({
    icon: String,
    title: {
        type: String,
        required: true
    },
    message: String,
    color: String,
    time: {
        type: Number,
        default: 5
    },
    autoClose: {
        type: Boolean,
        default: true
    }
})
const emit = defineEmits(['close'])

onMounted(() => {
    if(props.autoClose){
        setTimeout(() => {
            emit('close')
        }, props.time * 1000)
    }
})
</script>
