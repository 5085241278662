export function sortBy(key, options = {order:'asc', transformer: null}) {
    const {order, transformer} = options;
    return (a, b) => {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }
        let varA = a[key];
        let varB = b[key];

        if(transformer && typeof transformer == 'function'){
            varA = transformer(a[key])
            varB = transformer(b[key])
        }

        // const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key];
        // const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

export function getValueFromString(o, s) {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, '');           // strip a leading dot
    var a = s.split('.');
    for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i];
        if (o && k in o) {
            o = o[k];
        } else {
            return null;
        }
    }
    return o;
}