import { createApp } from 'vue'
import { router } from '@/plugins/router'
import { axiosConfig } from '@/plugins/axios'
import Root from '@/App.vue'
import './index.css'
import state from '@/state'
import axios from 'axios'
import clickOutside from './directive/click-outside'
import ripple from '@/directive/ripple'
import { capitalize } from '@/utils/strings'
import Toaster from "@meforma/vue-toaster"
import { defaultLocale } from './plugins/i18n'
//===============================
const app = createApp(Root);
app.use(router)
app.use(Toaster).provide('toast', app.config.globalProperties.$toast);
//===== directives ===================
ripple.color = {
    light: 'rgba(0, 0, 0, 0.35)',
    dark: 'rgba(255, 255, 255, 0.35)',
};
app.directive("ripple", ripple)
app.directive("click-outside", clickOutside)
app.provide('$state', state);
//===== axios ===================
axiosConfig(axios)
app.provide('$axios', axios);
//===============================
String.prototype.capitalize = function () {
    return capitalize(this)
}
//===============================
//==== Global Filters 
app.config.globalProperties.$filters = {
    formatDate(value) {
        let options = { year: 'numeric', month: 'long', day: 'numeric' };
        let date = new Date(value)
        return date.toLocaleDateString(defaultLocale(), options)
    }
}
//===============================
app.mount('#app')
