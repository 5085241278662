<template>
    <transition name="fade">
		<div v-if="!AppLoaded.value && store.state.pageLoading" class="fixed top-0 right-0 z-50 flex items-center justify-center w-screen h-screen bg-white dark:bg-slate-900">
			<img class="h-14" src="@/assets/logo.png" alt="">
			<svg class="w-10 h-10 ms-3 -me-1 animate-spin text-primary-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
				<circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
				<path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
			</svg>
		</div>
	</transition>
	<Header v-if="store.state.auth.logged" />
	
	<div class="relative flex bg-white content-h dark:bg-slate-800" v-if="store.state.auth.logged">
		<SideMenu :mini="store.state.page.menu.length > 0" />
		<SideSubMenu v-if="store.state.page.menu.length" :menu="store.state.page.menu" class="w-64" />
		<div class="relative flex-grow h-full overflow-x-hidden overflow-y-auto">
			<router-view v-slot="{ Component }">
				<transition name="page"  mode="out-in">
					<component :is="Component" />
				</transition>
			</router-view>
		</div>
	</div>
	<div v-else>
		<router-view v-slot="{ Component }">
			<transition name="page"  mode="out-in">
				<component :is="Component" />
			</transition>
		</router-view>
	</div>
	<div v-if="store.state.notification.open" class="fixed z-20 flex items-end justify-end h-32 bottom-8 end-8">
		<Notification class="z-20" v-bind="store.state.notification" @close="() => {store.setNotification({open: false})}" />
	</div>
</template>

<script setup>
import { inject, onBeforeMount, onMounted, ref } from 'vue';
import Header from '@/components/layouts/Header.vue'
import Notification from '@/components/elements/Notification.vue'
import SideMenu from '@/components/layouts/SideMenu.vue'
import SideSubMenu from '@/components/layouts/SideSubMenu.vue'

const AppLoaded = ref(false)
const store = inject('$state')

onBeforeMount(async () => {
	let darkMode = JSON.parse(window.localStorage.getItem('darkMode')) || false
	if(darkMode){
		document.documentElement.classList.add('dark')
	}
})

onMounted(async () => {
	let auth = window.localStorage.getItem('auth')
	if(auth){
		auth = JSON.parse(auth)
		store.setUser(auth.logged, auth)
	}else{
		store.logout()
	}
	AppLoaded.value = true
})

</script>

<style>

.page-enter-active{
	transition: opacity .8s ease,transform .3s cubic-bezier(1, 0.5, 0.8, 1);
}
.page-leave-active {
  transition: opacity .5s ease, transform .3s cubic-bezier(1, 0.5, 0.8, 1) .2s ;
}
.page-enter-from,
.page-leave-to {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	opacity: 0;
	transform: translateX(100px);
}
.page-leave-to{
	transform: translateX(-50px);
}
</style>