<template>
    <aside class="z-20 flex-shrink-0 hidden h-full overflow-x-hidden overflow-y-auto bg-white shadow-sm dark:bg-slate-800 md:block border-e dark:border-slate-900" style="--tw-border-opacity:.5" >
        <div class="py-4 text-gray-500 dark:text-gray-400" :class="{'w-64': !mini, 'w-16': mini}">
            <ul>
                <li class="relative py-3" :class="{'px-6': !mini, 'px-2': mini}" v-for="(menu, key) in menu_List" :key="key">
                    <span v-if="$route.name == menu.name" class="absolute inset-y-0 left-0 w-1 rounded-br-lg rounded-te-lg bg-primary-500" aria-hidden="true"></span>
                    <router-link class="flex items-center w-full text-sm font-medium transition-colors duration-150 hover:text-gray-800 dark:hover:text-gray-200" :class="{'justify-center': mini, 'text-primary-500': ($route.name === 'home' && menu.name === 'home' ) || (menu.name !== 'home'  && $route.path.includes($router.resolve({name: menu.name}).path)) }" :to="{name: menu.name}">
                        <template v-if="menu.icon_type == 'html'">
                            <span v-html="menu.icon"></span>
                        </template>
                        <template v-else>
                            <i :class="menu.icon"></i>
                        </template>
                        <span class="ms-4" v-if="!mini">{{ lang.$t(menu.name) }}</span>
                    </router-link>
                </li>
            </ul>
            <!-- <div class="px-6 my-6" v-if="!mini">
                <button class="flex items-center justify-between w-full px-4 py-2 text-sm font-medium leading-5 text-white transition-colors duration-150 border border-transparent rounded-lg bg-primary-600 active:bg-primary-600 hover:bg-primary-700 focus:outline-none focus:shadow-outline-primary">
                    Quick action
                    <span class="ml-2" aria-hidden="true">+</span>
                </button>
            </div> -->
        </div>
    </aside>
</template>
<script setup>
import {defineProps, inject, ref} from 'vue'
import {useI18n} from '@/plugins/i18n'
import {getSortedMenus} from '@/utils/menu'
const lang = useI18n()
const props =  defineProps({
    mini: Boolean
})
const menu_List = ref([])
menu_List.value = getSortedMenus()
</script>