<template>
	<Disclosure as="nav" class="relative z-40 text-gray-500 bg-white border-b print-hide dark:border-gray-900 dark:bg-slate-800" style="--tw-border-opacity: .5" v-slot="{ open }">
		<div class="px-4 ">
			<div class="relative flex items-center justify-between h-16">
				<div class="absolute inset-y-0 flex items-center start-0 sm:hidden">
					<!-- Mobile menu button-->
					<DisclosureButton class="inline-flex items-center justify-center p-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-200">
						<span class="sr-only">Open main menu</span>
						<Bars3Icon v-if="!open" class="block w-6 h-6" aria-hidden="true" />
						<XMarkIcon v-else class="block w-6 h-6" aria-hidden="true" />
					</DisclosureButton>
				</div>
				<div class="flex items-center justify-center flex-1 sm:items-stretch sm:justify-start">
					<div class="flex items-center ">
						<img class="w-auto h-8" src="@/assets/logo.png" alt="Workflow" />
					</div>

					<!-- <div class="flex justify-center flex-1 ps-8 me-40">
						<div class="relative w-full max-w-xl me-6 focus-within:text-primary-500">
							<div class="absolute inset-y-0 flex items-center ps-2">
								<svg class="w-4 h-4" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20">
									<path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
								</svg>
							</div>
							<input class="py-3 mt-0" type="text" placeholder="Search for anything" aria-label="Search">
						</div>
					</div> -->

					<!-- <div class="hidden sm:block sm:ms-6">
						<div class="flex space-s-4">
							<a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700', 'px-3 py-2 rounded-md text-sm font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
						</div>
					</div> -->
				</div>
				<div class="absolute inset-y-0 flex items-center pe-2 end-0 sm:static sm:inset-auto sm:ms-6 sm:pe-0">
			
					<button v-ripple class="p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-primary-200 me-3" @click="toggleTheme" aria-label="Toggle color mode">
						<MoonIcon class="w-5 h-5 dark:hidden" />
						<SunIcon class="hidden w-5 h-5 dark:block" />
					</button>

					<!-- Profile dropdown -->
					<Menu as="div" class="relative me-3">
						<div>
							<MenuButton v-ripple class="p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-primary-200">
								<span class="sr-only">View notifications</span>
								<GlobeAltIcon class="w-6 h-6" aria-hidden="true" />
							</MenuButton>
						</div>
						<transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
							<MenuItems class="absolute w-32 py-1 mt-2 bg-white rounded-md shadow-lg end-0 origin-top-end ring-1 ring-black ring-opacity-5 focus:outline-none">
								<MenuItem v-slot="{ active }" v-for="(locale, key) in supportedLocales" :key="key">
									<button class="block w-full px-4 py-2 text-sm text-gray-700" :class="{'bg-gray-100': active}" @click="changeLocale(locale)">{{ lang.messages[locale].language }}</button>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>

					<Menu as="div" class="relative me-3">
						<div>
							<MenuButton v-ripple class="p-1 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-primary-200">
								<span class="sr-only">View notifications</span>
								<BellIcon class="w-6 h-6" aria-hidden="true" />
								<span class="absolute top-0 right-0 flex w-2 items-center justify-center h-2 -mt-0.5 -ms-0.5">
									<span class="absolute inline-flex w-full h-full rounded-full opacity-75 bg-primary-400 animate-ping"></span>
									<span class="relative inline-flex w-1.5 h-1.5 rounded-full bg-primary-500"></span>
								</span>
							</MenuButton>
						</div>
						<transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
							<MenuItems class="absolute w-64 py-1 mt-2 bg-white rounded-md shadow-lg end-0 origin-top-end ring-1 ring-black ring-opacity-5 focus:outline-none">
								<MenuItem v-slot="{ active }">
									<a href="#" class="block px-4 py-2 text-sm text-gray-700 bg-yellow-200" :class="{'bg-gray-100': active}">You have promocode wow50 use it until 12/2021</a>
								</MenuItem>
								<MenuItem v-slot="{ active }">
									<a href="#" class="block px-4 py-2 text-sm text-gray-700" :class="{'bg-gray-100': active}">Congrats you wins 50 credits in your wallet</a>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
					
					<!-- Profile dropdown -->
					<Menu as="div" class="relative ms-3">
						<div>
						<MenuButton v-ripple class="flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-transparent focus:ring-primary-200">
							<span class="sr-only">Open user menu</span>
							<img class="w-8 h-8 rounded-full" :src="store.state.auth.user.image || '/assets/profile-default.jpg'" :alt="store.state.auth.user.firstName" />
							<div class="px-2 leading-3 text-start" v-if="store.state.auth.user">
								<div>{{ store.state.auth.user.name }}</div>
								<div class="mt-1 text-xs text-primary-500">{{ parseInt(store.state.auth.user.credit_amount || 0).toFixed(2) }} {{ lang.currency.value }}</div>
							</div>
						</MenuButton>
						</div>
						<transition enter-active-class="transition duration-100 ease-out" enter-from-class="transform scale-95 opacity-0" enter-to-class="transform scale-100 opacity-100" leave-active-class="transition duration-75 ease-in" leave-from-class="transform scale-100 opacity-100" leave-to-class="transform scale-95 opacity-0">
							<MenuItems class="absolute w-48 py-1 mt-2 bg-white rounded-md shadow-lg end-0 origin-top-end ring-1 ring-black ring-opacity-5 focus:outline-none">
								<MenuItem v-slot="{ active }">
									<router-link :to="{name: 'profile'}" class="block px-4 py-2 text-sm text-gray-700" :class="{'bg-gray-100': active}">{{ lang.$t('myAccount') }}</router-link>
								</MenuItem>
								<MenuItem v-slot="{ active }">
									<router-link :to="{name: 'user-settings'}" class="block px-4 py-2 text-sm text-gray-700" :class="{'bg-gray-100': active}">{{ lang.$t('settings') }}</router-link>
								</MenuItem>
								<MenuItem v-slot="{ active }">
									<a href="#" @click="logout" class="block px-4 py-2 text-sm text-gray-700" :class="{'bg-gray-100': active}" >{{ lang.$t('logout') }}</a>
								</MenuItem>
							</MenuItems>
						</transition>
					</Menu>
				</div>
			</div>
		</div>
<!-- 
		<DisclosurePanel class="sm:hidden">
			<div class="px-2 pt-2 pb-3 space-y-1">
				<a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700', 'block px-3 py-2 rounded-md text-base font-medium']" :aria-current="item.current ? 'page' : undefined">{{ item.name }}</a>
			</div>
		</DisclosurePanel> -->
	</Disclosure>
</template>

<script setup>
import { ref, inject } from 'vue'
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import { BellIcon, Bars3Icon, XMarkIcon, GlobeAltIcon, MoonIcon, SunIcon } from '@heroicons/vue/24/outline'
import { useI18n, supportedLocales, changeLocaleTo} from '@/plugins/i18n'
import {useRouter} from 'vue-router'
const navigation = [
  { name: 'Dashboard', href: '#', current: true },
  { name: 'Team', href: '#', current: false },
  { name: 'Projects', href: '#', current: false },
  { name: 'Calendar', href: '#', current: false },
]
const store = inject('$state')
const lang = useI18n()
const router = useRouter()
const open = ref(false)
const locales = supportedLocales

const changeLocale = (locale) => {
	changeLocaleTo(locale, lang)
}

const toggleTheme = () => {
	document.documentElement.classList.toggle('dark');
	let darkMode = document.documentElement.classList.contains('dark')
	window.localStorage.setItem('darkMode', JSON.stringify(darkMode))
}

const logout = () => {
	store.logout()
	
	// api.get('/users/logout').then((res) => {
	// 	if(res.data.status){
    //         store.logout()
	// 		// store.setNotification({
    //         //     open: true,
    //         //     title: lang.$t('logoutSuccessTitle'),
    //         //     class: ' bg-red-100 ',
	// 		// 	color: 'text-red-900'
    //         // })
    //     }
	// })
}
</script>