import store from "@/state";
export const axiosConfig = (axios) => {
    axios.defaults.baseURL = import.meta.env.VITE_BEASE_URL

    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        
        if(response.data.hasOwnProperty('status')){
            if(response.data.status && response.config.method != 'get' || !response.data.status ){
                store.setNotification({
                    open: true, 
                    title: response.data.message,
                    class: response.data.status ? 'bg-green-100' : 'bg-red-100', 
                    color: response.data.status ? 'text-green-900' : 'text-red-900',
                    icon: !response.data.status ? 'error' : '',
                })
            }
        }
        return response.data;
    }, function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if(error.response){
            if(error.response.status == 401){
                store.logout()
            }
            let error_msg = ''
            if(error.response.data){
                if(error.response.data.errors){
                    error_msg = Object.values(error.response.data.errors).map(v => {
                        return v.join("\n")
                    }).join("\n")
                }else{
                    error_msg = error.response.data.message
                }
            }else{
                error_msg = error.response
            }
            store.setNotification({
                open: true, 
                title: error_msg,
                class: 'bg-red-100', color: 'text-red-900', icon: 'error',
            })
        }

        return Promise.reject(error);
    });
}